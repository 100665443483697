<template>
  <div class="order-list-container">
    <Table
      :columns="columns"
      :data="list"
    />
    <div class="page-contianer">
      <Page
        id="orderListPage"
        class="orderListPage"
        :total="orderTotal"
        show-sizer
        show-total
        show-elevator
        @on-change="changePage"
        @on-page-size-change="changePageSize"
        :page-size="pageSize"
      />
    </div>
  </div>
</template>

<script>
import order from './orders'

export default {
  mixins : [order]
}
</script>

<style lang="stylus">
.order-list-container
  margin-top 30px
  padding 30px
  background #fff
  border-radius 20px
  min-height 600px
  .ivu-table-header thead tr th
    padding 12px 0
    background-color #F1F1F3
  .btn-action
    margin-right 15px
    background #03C9FF
    border 1px solid #03C9FF
  .page-contianer
    margin-top 50px
    text-align center

</style>

