let order = {
    data() {
        return {
            pageSize: 10,
            pageNumber: 1,
            orderTotal: 0,
            columns: [
                {
                    title: '订单号',
                    key: 'order_no',
                    align: 'center'
                },
                {
                    title: '下单时间',
                    key: 'create_time',
                    align: 'center'
                },
                {
                    title: '订单状态',
                    key: 'order_status_str',
                    align: 'center'
                },
                {
                    title: '项目名称',
                    key: 'vprojectname',
                    align: 'center'
                },
                {
                    title: '所属公司',
                    key: 'team_corp_name',
                    align: 'center'
                },
                {
                    title: '班组名称',
                    key: 'vteam_name',
                    align: 'center'
                },
                {
                    title: '购买人数',
                    key: 'goods_quantity',
                    align: 'center'
                },
                {
                    title: '单价',
                    key: 'goods_price',
                    align: 'center'
                },
                {
                    title: '总价',
                    key: 'order_amount',
                    align: 'center'
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 250,
                    align: 'center',
                    render: (h, params) => {
                        switch (params.row.order_status) {
                            case 0:
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            type: 'primary',
                                            size: 'small',
                                        },
                                        style: {
                                            background: '#03C9FF',
                                            border: '1px solid #03C9FF',
                                            marginRight: '15px'
                                        },
                                        on: {
                                            click: () => {
                                                this.cancelOrder(params.row.id)
                                            }
                                        }
                                    }, '取消订单'),
                                    h('Button', {
                                        props: {
                                            type: 'primary',
                                            size: 'small',
                                        },
                                        style: {
                                            background: '#03C9FF',
                                            border: '1px solid #03C9FF',
                                        },
                                        on: {
                                            click: () => {
                                                this.payOrder(params.row);
                                            }
                                        }
                                    }, '支付订单'),
                                ]);
                            case 5 :
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            type: 'primary',
                                            size: 'small',
                                        },
                                        style: {
                                            background: '#03C9FF',
                                            border: '1px solid #03C9FF'
                                        },
                                        on: {
                                            click: () => {
                                                this.invoice(params.row.id)
                                            }
                                        }
                                    }, '开具发票')
                                ]);
                            default :
                        }
                    }
                }],
            list: [
                {
                    orderId: '12',
                    name: '12e3',
                    price: '244',
                    sumPrice: 1223,
                    tname: '23',
                    time: '2020-12-2',
                    status: '32324'
                },
                {
                    orderId: '1qwqw2',
                    name: '123',
                    price: '244',
                    sumPrice: 1223,
                    tname: '23',
                    time: '2020-12-2',
                    status: '32324'
                }
            ],
            purchaseForm: {
                order_amount: 150.00,
                creator_id: "",
                creator_company_id: "",
                creator_name: "",
                creator_company_name: "",
                team_id:"",
                project_id:"",
                team_name:"",
                corp_name:"",
                project_name:"",
                goods_id: "",
                goods_name: "",
                goods_quantity: 10,
                goods_price: 150.00,
                purchase_year: 1,
                orderItems: []
            },
        }
    },
    activated() {
        // 重新进入页面就会实现
        this.getOrders();
    },
    methods: {
        async getOrders() {
            let userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
            let param = {"creator_id": userInfo.id, "pageNumber": this.pageNumber, "pageSize": this.pageSize};
            let res = await this.$fetch("/api/api/buyer/order/list", {
                method: 'post',
                data: param
            });

            if (res) {
                if (res.code == "200") {
                    this.list = res.resData.list;
                    this.orderTotal = res.resData.totalRow;
                } else {
                    this.$Message.error(res.data.message);
                    if (res.data.code === "6000") {
                        this.$router.push({name:"login"});
                    }
                    console.log(res);
                }
            }
        },
        async cancelOrder(id) {
            let token = this.common.getAuth();
            let userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
            let param = {
                "order_id" : id,
                "creator_id": userInfo.id
            };
            let res = await this.$fetch("/api/api/buyer/order/cancel", {
                method:"post",
                data: param
            },{
                "headers": {
                    "Eova-Authorization": token
                }
            });

            if (res) {
                if (res.code == "200") {
                    this.$Message.success("取消成功");
                    this.getOrders();
                } else {
                    this.$Message.error("取消失败");
                    this.$Message.error(res.data.message);
                    if (res.data.code === "6000") {
                        this.$router.push({name:"login"});
                    }
                    console.log(res);
                }
            }
        },
        invoice(id) {
           this.$router.push({name : "invoice", params:{orderId:id}});
        },
        payOrder(form) {
            this.purchaseForm = form;
            this.$router.push({
                name: 'confirmOrder',
                query: {
                    orderId: form.id,
                    purchaseForm: JSON.stringify(this.purchaseForm),
                }
            });
        },
        changePage (index) {
            this.pageNumber = index;
            this.getOrders();
        },
        changePageSize(size) {
            this.pageSize = size;
            this.getOrders();
        }
    },
};

export default order